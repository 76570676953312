import { ApiRoutes } from "../../../api/ApiRoutes";
import { IAddressee } from "../../../types/ApiTypes";
import { ICollection } from "../../../types/collection.types";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useAddressees = () => {
    const { data, isLoading, mutate } = useTiggiQuery<ICollection<IAddressee>>(ApiRoutes.Addressees.All);

    return {
        addressees: data?.items ?? [],
        addresseesById: data?.byId ?? {},
        loadingAddressees: isLoading,
        reloadAddressees: mutate
    }
} 