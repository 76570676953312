import React from "react";
import { clientImportLogsDeleteById } from "../../api/Api";
import { AppColor } from "../../app/AppStyles";
import useApi from "../../hooks/useApi";
import useDatevImport from "../../hooks/useDatevImport";
import { useClientImportLog } from "../../state/swr/clients/useClientImportLog";
import { IClientImportLogEntry } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import Card from "../card/Card";
import Icon from "../icons/Icon";
import LoadingSpinner from "../loader/LoadingSpinner";
import "./ImportClientsForm.css";
import { useLatestClientImportLog } from "../../state/swr/clients/useLatestClientImportLog";
import Typography from "../text/Typography";

export default function ClientImportProgress() {

    const {
        latestClientImportLog
    } = useLatestClientImportLog();

    return null;

    // if (!latestClientImportLog) return null;

    // if (latestClientImportLog && latestClientImportLog.completedAt) {
    //     return (
    //         <Card>
    //             <div className="d-flex flex-row align-items-center justify-content-between w-100">
    //                 <div className="d-flex flex-row align-items-center">
    //                     <Icon icon="check" color="success" size={30} />
    //                     <span>Import abgeschlossen!</span>
    //                 </div>
    //                 <DeleteClientImportLogButton log={latestClientImportLog} />
    //             </div>
    //         </Card>
    //     )
    // }

    // if (latestClientImportLog && latestClientImportLog.failed) {
    //     return (
    //         <Card>
    //             <div className="d-flex flex-row align-items-center justify-content-between w-100">
    //                 <div className="d-flex flex-row align-items-center">
    //                     <Icon icon="x" color="error" size={30} />
    //                     <span>Es ist ein Fehler aufgetreten!</span>
    //                 </div>
    //                 <DeleteClientImportLogButton log={latestClientImportLog} text="Okay" color="error" />
    //             </div>
    //         </Card>
    //     )
    // }

    // return (
    //     <Card
    //         header={<Typography bold>Aktueller Import</Typography>}
    //     >
    //         <div className="w-100">
    //             <div className="d-flex flex-row align-items-center justify-content-between w-100">
    //                 <div className="d-flex flex-column gap-2">
    //                     <LoadingSpinner size={18} text={latestClientImportLog.message} centered={false} />
    //                     {
    //                         latestClientImportLog.allErrors && !!latestClientImportLog.allErrors.length && <span>{latestClientImportLog.allErrors.length} Fehler</span>
    //                     }
    //                 </div>
    //                 <DeleteClientImportLogButton log={latestClientImportLog} text="Ausblenden" color="error" />
    //             </div>
    //         </div>
    //     </Card>
    // )
}

function DeleteClientImportLogButton({log}: {log: IClientImportLogEntry, text?: string, color?: AppColor}) {

    const { reloadLatestClientImportLog } = useLatestClientImportLog();
    const { reloadDatevImport } = useDatevImport();

    const callApi = useApi();

    const deleteClientImportLog = async () => {
        await callApi(clientImportLogsDeleteById({importId: log._id}));

        await Promise.all([
            reloadDatevImport(),
            reloadLatestClientImportLog()
        ]);
    }

    const color = log.completedAt ? "success" : "error";
    const text = log.completedAt ? "Schließen" : (log.failed ? "Fehler ausblenden" : "Ausblenden");

    return (
        <Button className="align-self-end" text={text} onClick={deleteClientImportLog} color={color} loadingText="Bitte warten..." />
    )
}