import React from "react";
import useSWR, { MutatorCallback, MutatorOptions } from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { ISession } from "../../../types/ApiTypes";
import { Cookies, deleteCookie, setCookie } from "../../../util/cookies";
import { useUser } from "../user/useUser";

export const useSession = () => {
    const [ token, setToken ] = React.useState<string>();
    const { data, isLoading, mutate } = useSWR<ISession>(ApiRoutes.Session.Active);

    const { reloadUser } = useUser();

    const setSessionToken = (t: string | null | undefined) => {
        if (!t) {
            deleteCookie(Cookies.Session);
            setToken("");
            return;
        }

        setCookie(Cookies.Session, t);
        setToken(t);
    }

    const reloadSession = async (data?: ISession | Promise<ISession | undefined> | MutatorCallback<ISession> | undefined, opts?: boolean | MutatorOptions<ISession> | undefined) => {
        await mutate(data, opts);
        await reloadUser(undefined, { revalidate: true });
    }

    return {
        session: data,
        setSessionToken: setSessionToken,
        sessionToken: token,
        loading: isLoading,
        reloadSession: reloadSession
    }
} 