import React from "react";
import { datevImportCancel, datevImportsResume } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { useClientImportLog } from "../../state/swr/clients/useClientImportLog";
import { useDatevImports } from "../../state/swr/datevImport/useDatevImports";
import { IDatevImport } from "../../types/datevImport.schema";
import { ExternalService } from "../../types/log.schema";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import LoadingSpinner from "../loader/LoadingSpinner";
import Pill from "../pills/Pill";
import ExternalServiceBanner from "../services/ExternalServiceBanner";
import Table from "../tables/Table";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import Typography from "../text/Typography";
import RoleBanner from "../user/RoleBanner";
import "./ImportClientsForm.css";
import ModalDialog from "../modal/ModalDialog";

export default function CurrentClientImports() {

    const {
        datevImports,
        reloadDatevImports,
        loadingDatevImports
    } = useDatevImports();

    const callApi = useApi();

    if (loadingDatevImports) return <LoadingSpinner />

    return (
        <Table
            headers={[
                { label: "Startdatum" },
                { label: "Status" },
                { label: "Erstellt von" },
                { label: "Aktionen" }
            ]}
            items={datevImports ?? []}
            renderItem={d => (
                <TableRow>
                    <TableCell date={d.createdAt} />
                    <TableCell>
                        <ImportLogMessage id={d.importLog } />
                    </TableCell>
                    <TableCell>
                        <Flex>
                            <ExternalServiceBanner service={d.serviceSource} />
                            <RoleBanner user={d.importingUser} displayNameAsValue />
                        </Flex>
                    </TableCell>
                    <TableCell>
                        <Flex align="end">
                            {
                                d.completed 
                                ? <Pill text="Erfolgreich abgeschlossen!" color="success" />
                                : (
                                    <Button
                                        icon={d.cancelled ? "check" : "x"}
                                        text={d.cancelled ? "Erneut starten" : "Abbrechen"}
                                        color={d.cancelled ? "success" : "error"}
                                        onClick={async () => {
                                            const res = (
                                                d.cancelled
                                                ? await callApi(datevImportsResume({ id: d._id })) 
                                                : await callApi(datevImportCancel({ id: d._id }))
                                            );

                                            await reloadDatevImports();
                                        }}
                                    />
                                )
                            }
                        </Flex>
                    </TableCell>
                </TableRow>
            )}

        />
    )
}

function ImportLogMessage({ id }: { id: string}) {

    const [lastText, setLastText] = React.useState<string>("-");

    const interval = React.useRef<any>(null);

    const {
        clientImportLog,
        loadingClientImportLog,
        reloadClientImportLog
    } = useClientImportLog(id);

    React.useEffect(() => {
        if (!clientImportLog) return;
        setLastText(clientImportLog?.message ?? "-");
    }, [clientImportLog]);

    React.useEffect(() => {
        if (!clientImportLog || !!clientImportLog.completedAt) return () => clearInterval(interval.current);
        interval.current = setInterval(async () => await reloadClientImportLog(undefined, { revalidate: true }), 5000);
        return () => clearInterval(interval.current);
    }, [clientImportLog]);

    return (
        <Flex row>
            <Icon
                loading={loadingClientImportLog}
                icon="arrow-clockwise"
                onClick={() => reloadClientImportLog(undefined, { revalidate: true })}
            />
            <Typography wrap>
                {lastText}
            </Typography>
            {
                !!(clientImportLog?.allErrors?.length) && (
                    <ModalDialog
                        button={<Pill color="#FF0000" text={`Fehler (${clientImportLog.allErrors.length})`} />}
                        title="Import-Fehler"
                    >
                        {
                            clientImportLog.allErrors.map((e, i) => (
                                <Typography key={i} wrap>
                                    {e.errorId ?? `00${i}`} - {e.errorMessage ?? "Unbekannter Fehler"}
                                </Typography>
                            ))
                        }
                    </ModalDialog>
                    
                )
            }
        </Flex>
    )
}